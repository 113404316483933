<template>
  <div>
    <PageHeaderLayout>
      <div class='main-page-content'>
        <el-row class='table-header'>
          <el-col>
            <el-button type='primary' @click='initList'>刷新<i class='el-icon-refresh'></i></el-button>
            <el-button type='warning' @click='autoReadNotify'>全部标记为已读&nbsp;<i class='el-icon-folder-checked'></i>
            </el-button>
          </el-col>
        </el-row>
        <div>
          <el-row>
            <el-col :span='24'>
              <div class='block'>
                <div class='notify-content'>
                  <el-timeline>
                    <el-timeline-item :timestamp='`${notify.created_at}`' placement='top'
                                      v-for='(notify,index) in notifyList' :key='index'>
                      <el-card v-if="mode==='card'" :shadow="`${notify.status===1?'always':'hover'} `">
                        <el-link :underline='false' type='info' @click='readNotify(notify,true)'
                                 :title='`${notify.url?"点击跳转":""}`'>
                          <i :class="`el-icon-folder-checked ${notify.status===1?'read':'unread'}`"
                             v-if='notify.status===1'></i>
                          <i :class="`el-icon-folder ${notify.status===1?'read':'unread'}`" v-else></i>
                          <span class='title'>{{ notify.object }}</span>
                          <p>{{ notify.sender_name }} {{ notify.message }}</p>
                        </el-link>
                      </el-card>
                      <div v-else>
                        <el-link :underline='false' type='info' @click='readNotify(notify,true)'
                                 :title='`${notify.url?"点击跳转":""}`'>
                          <i :class="`el-icon-folder-checked ${notify.status===1?'read':'unread'}`"
                             v-if='notify.status===1'></i>
                          <i :class="`el-icon-folder ${notify.status===1?'read':'unread'}`" v-else></i>
                          <span>【{{ notify.object }}：{{ notify.sender_name }}】 {{ notify.message }}</span>
                        </el-link>
                      </div>
                    </el-timeline-item>
                  </el-timeline>
                </div>

                <el-row>
                  <el-col :span='24' style='text-align: right'>
                    <m-pagination :total.sync='pagingData.total' :limit.sync='pagingData.page_size'
                                  :page.sync='pagingData.current_page'
                                  @pagination='initList' />
                    <!--                    <el-pagination-->
                    <!--                        v-if="initPaging"-->
                    <!--                        :current-page="currentPage"-->
                    <!--                        :page-sizes="pageSizes"-->
                    <!--                        :page-size="pageSize"-->
                    <!--                        :layout="defaultLayout"-->
                    <!--                        :total="dataTotal"-->
                    <!--                        background-->
                    <!--                        @size-change="handleSizeChange"-->
                    <!--                        @current-change="handleCurrentChange"-->
                    <!--                    />-->
                  </el-col>
                </el-row>
              </div>
            </el-col>
          </el-row>
        </div>

      </div>
    </PageHeaderLayout>
  </div>
</template>

<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import TreeTable from '@/components/TreeTable'
import ModalDialog from '@/components/ModalDialog'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'NotifyIndex',
  components: {
    PageHeaderLayout,
    TreeTable,
    ModalDialog
  },
  data() {
    return {
      currentPage: 1,
      apePageSize: 15,
      // 分页信息
      pagingData: {
        is_show: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        page_size: 15,
        current_page: 1,
        offset: 0
      },
      notifyList: [
        // {
        //   object: '新增红人',
        //   sender_name: '超级管理员',
        //   message: '【新增红人】时间：2021/06/16 15:11，用户：超级管理员，红人昵称：AA',
        //   created_at: '2021-06-16'
        // }
      ],
      mode: 'line'
    }
  },
  computed: {
    ...mapGetters(['userPermissions', 'notifyTotal'])
  },
  created() {
    this.initList()
  },
  methods: {
    ...mapMutations([
      'changeNotifyTotal'
    ]),
    async initList() {
      this.notifyList = []
      let pagingInfo = { current_page: this.pagingData.current_page, page_size: this.pagingData.page_size }
      let searchCondition = {}
      Object.assign(searchCondition, pagingInfo)
      let { list, pages } = await this.$api.getNotifyList(searchCondition)
      this.pagingData.total = pages.total * 1
      this.pagingData.offset = pages.offset * 1

      this.$nextTick(() => {
        this.notifyList = list
        setTimeout(() => {
          this.autoReadNotify(false)
        }, 500)
      })
    },
    readNotify(row, jump) {
      if (row.status === 0) {
        this.changeNotifyTotal(this.notifyTotal - 1)
        this.$api.readNotify({ id: row.id })
        row.status = 1
      }
      if (jump && row.url && row.url.length > 0) {
        if (row.url[0] === '/')
          this.$router.push({ path: row.url })
      }
      // this.$notify.success('已读')
    },
    autoReadNotify() {
      //自动标记为已读
      this.notifyList.forEach((item) => {
        if (item.status === 0) {
          this.readNotify(item, false)
        }
      })
    }
  },
  destroyed() {
    // console.log('1 destroyed page')
  }
}
</script>

<style scoped>

.notify-content {
  width: 800px;
  min-width: 600px;
  max-width: 1300px;
  margin: auto;
  /*border: #ff3176 dashed 1px;*/
  padding: 10px;
  text-align: left;
  min-height: 600px;
}

.title {
  font-weight: 800;
  font-size: 1rem;
}

.read {
  color: #909399;
  font-size: 0.8rem;
  font-weight: initial;
}

.unread {
  color: #F56C6C;
  font-size: 1rem;
  font-weight: bold;
}
</style>
